<template>
  <a-form @submit="handleSubmit" :form="form">
    <a-form-item v-bind="formItemLayout" label="Type attestation">
      <a-select
        v-decorator="[
          'select',
          {
            rules: [
              {
                required: true,
                message: 'Type d\'attestation est obligatoire!',
              },
            ],
          },
        ]"
        placeholder="Type attestation"
      >
        <a-select-option value="13">Visa</a-select-option>
        <a-select-option value="16">Assurance</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item v-bind="formItemLayout" label="Adhérente">
      <a-select
        v-decorator="[
          'select3',
          {
            rules: [{ required: true, message: 'Adhérente  est obligatoire!' }],
          },
        ]"
        placeholder="Choisisie un adhérente"
      >
        <a-select-option value="13">Ibtisem</a-select-option>
        <a-select-option value="16">Karim</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item v-bind="formItemLayout">
      <span slot="label">
        Obeservation
        <a-tooltip title="Observation ?"> </a-tooltip>
      </span>
      <a-textarea
        v-decorator="[
          'observation',
          {
            rules: [
              {
                required: false,
                message: 'Le prenom est obligatoire!',
                whitespace: true,
              },
            ],
          },
        ]"
      />
    </a-form-item>
    <a-row>
      <a-col :span="2" :offset="6">
        <a-form-item v-bind="tailFormItemLayout">
          <a-button type="primary" htmlType="submit">Confimer</a-button>
        </a-form-item>
      </a-col>
      <a-col :span="2" :offset="1">
        <a-form-item v-bind="tailFormItemLayout">
          <a-button type="default">Annuler</a-button>
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
import ATextarea from "ant-design-vue/es/input/TextArea";

export default {
  name: "AttestationsEditor",
  components: { ATextarea },
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  data() {
    return {
      confirmDirty: false,

      autoCompleteResult: [],
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 6 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 8 },
        },
      },
      tailFormItemLayout: {
        wrapperCol: {
          xs: {
            span: 8,
            offset: 0,
          },
          sm: {
            span: 8,
            offset: 2,
          },
        },
      },
    };
  },
  methods: {
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFieldsAndScroll((err, values) => {
        // if (!err) {
        // }
      });
    },
    handleConfirmBlur(e) {
      const value = e.target.value;
      this.confirmDirty = this.confirmDirty || !!value;
    },
  },
};
</script>
